import React, { FC } from 'react';

import NFButton from 'components/common/NFButton';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { parseNFButton } from 'components/common/NFButton/parsers';

import { INewsLetterThanksViewComponentProps } from './model';
import './NewsLetterThanksView.scss';

const NewsLetterThanksView: FC<INewsLetterThanksViewComponentProps> = ({ body }) => {
  const yourPharmacyLogo = body.yourPharmacyLogo[0].properties;

  return (
    <div className="newsletter-thanks-view">
      <a
        className="align-self-center general-pharmacy-logo"
        href={yourPharmacyLogo?.link}
        aria-label={yourPharmacyLogo.ariaLabel}
      >
        {yourPharmacyLogo.logo?.gatsbyImage?.childImageSharp ? (
          <GatsbyImage
            fluid={yourPharmacyLogo.logo?.gatsbyImage?.childImageSharp?.fluid}
            alt={yourPharmacyLogo?.logoAlt}
            imgStyle={{ objectFit: 'contain' }}
          />
        ) : (
          <DangerouslySetInnerHtml
            className="text-logo"
            html={yourPharmacyLogo.logo?.svg?.content}
          />
        )}
      </a>
      <NFButton
        type="submit"
        variant="primary"
        size="lg"
        {...parseNFButton(body?.productsRangeBtn[0].properties)}
      />

      <ul className="list-pharmacy-shops">
        {body?.partnersList.map(({ properties }) => (
          <li key={properties?.link}>
            <a href={properties?.link} target="_blank" rel="noreferrer">
              {properties.logo.gatsbyImage?.childImageSharp ? (
                <GatsbyImage
                  fluid={properties.logo.gatsbyImage?.childImageSharp?.fluid}
                  alt={properties?.logoAlt}
                  imgStyle={{ objectFit: 'contain' }}
                />
              ) : (
                <DangerouslySetInnerHtml
                  className="text-logo"
                  html={properties.logo?.svg?.content}
                />
              )}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewsLetterThanksView;
