import React, { FC, useCallback, useEffect, useState } from 'react';
import { Experiment, Variant } from 'react-optimize';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import dayjs from 'dayjs';

import { NEWSLETTER_FORM_ACTION, NEWSLETTER_POPUP_COOKIE } from 'utils/constants';
import { getCookie } from 'utils/helpers';
import useModal from 'hooks/useModal';
import NewsLetter from 'components/NewsLetter';
import Modal from 'components/Modal';

import 'components/NewsLetter/NewsLetter.scss';

const NewsletterPopups: FC<{ popups: NewsletterTypes.INewsletterPopup[] }> = React.memo(
  ({ popups }) => {
    const { isVisibleModal, openModal, closeModal } = useModal(false);
    const [isShown, setIsShown] = useState(false);
    const [cookie, setCookie] = useState<{
      action: NewsletterTypes.TNewsletterCookieAction;
      expiration: Date;
    }>();

    useEffect(() => {
      let modalTimer;
      const renderTimer = setTimeout(async () => {
        setIsShown(true);
      }, 5000);
      if (!getCookie(NEWSLETTER_POPUP_COOKIE)) {
        modalTimer = setTimeout(() => {
          openModal();
        }, 30000);
      }

      return () => {
        clearTimeout(renderTimer);
        clearTimeout(modalTimer);
      };
    }, []);

    useEffect(() => {
      if (cookie)
        document.cookie = `${NEWSLETTER_POPUP_COOKIE}=${cookie?.action}; expires=${cookie?.expiration}; path=/`;
    }, [cookie]);

    const onCloseModal = useCallback(() => {
      closeModal();
      !getCookie(NEWSLETTER_POPUP_COOKIE) &&
        setCookie({
          action: NEWSLETTER_FORM_ACTION.CLOSED,
          expiration: new Date(dayjs().add(7, 'days').format()),
        });
    }, []);

    return isShown ? (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >
        <Experiment id={process.env.GATSBY_GOOGLE_OPTIMIZE_EXPERIMENT_ID || ''}>
          {popups
            .filter((popup) => popup)
            .map((modal, i) => {
              const newsletterBannerProps = modal?.newsletterBanner?.[0]?.properties;
              const thankYouBannerProps =
                modal?.thankYouPopup?.[0]?.properties?.thankYouBanner?.[0]?.properties;
              const thankYouPopupProps = modal?.thankYouPopup?.[0]?.properties;

              return (
                <Variant id={`${i}`} key={modal?.alias}>
                  <Modal
                    className={modal?.alias}
                    isVisible={isVisibleModal}
                    onClose={onCloseModal}
                    closeIconColor={modal?.closeIconColor?.[0]?.properties?.colorPicker?.label}
                    closeBtnAriaLabel={modal?.closeBtnAriaLabel}
                  >
                    <NewsLetter
                      banner={{
                        formView: {
                          bannerLogo: newsletterBannerProps?.bannerLogo,
                          bannerGlowingImage: newsletterBannerProps?.bannerGlowingImage,
                          bannerGlowingImageAlt: newsletterBannerProps?.bannerGlowingImageAlt,
                          bannerCharacterImage: newsletterBannerProps?.bannerCharacterImage,
                          bannerCharacterImageAlt: newsletterBannerProps?.bannerCharacterImageAlt,
                          bannerBgColor:
                            newsletterBannerProps?.bannerBgColor?.[0]?.properties?.colorPicker
                              ?.label,
                          title: newsletterBannerProps?.title,
                          subtitle: newsletterBannerProps?.subtitle,
                        },
                        thanksView: {
                          bannerLogo: thankYouBannerProps?.bannerLogo,
                          bannerLogoSign: thankYouBannerProps?.bannerLogoSign,
                          bannerGlowingImage: thankYouBannerProps?.bannerGlowingImage,
                          bannerGlowingImageAlt: thankYouBannerProps?.bannerGlowingImageAlt,
                          bannerCharacterImage: thankYouBannerProps?.bannerCharacterImage,
                          bannerCharacterImageAlt: thankYouBannerProps?.bannerCharacterImageAlt,
                          bannerBgColor:
                            thankYouBannerProps?.bannerBgColor?.[0]?.properties?.colorPicker?.label,
                          title: thankYouBannerProps?.title,
                          subtitle: thankYouBannerProps?.subtitle,
                          description: thankYouBannerProps?.description,
                          promocode: thankYouBannerProps?.promocode,
                          promocodeCopyText: thankYouBannerProps?.promocodeCopyText,
                          promocodeCopiedText: thankYouBannerProps?.promocodeCopiedText,
                        },
                      }}
                      body={{
                        formView: {
                          checkboxes: modal.checkboxes,
                          checkboxValidationMsg: modal.checkboxValidationMsg,
                          consentText: modal?.consentText,
                          inputs: modal?.formInputs,
                          signUpBtn: modal?.signUpBtn,
                          campaignName: modal?.campaignName,
                          requiredLabel: modal?.requiredLabel,
                        },
                        thanksView: {
                          productsRangeBtn: thankYouPopupProps?.productsRangeBtn,
                          yourPharmacyLogo: thankYouPopupProps?.yourPharmacyLogo,
                          partnersList: thankYouPopupProps?.partnersList,
                        },
                      }}
                      onNewsletterSubmit={setCookie}
                    />
                  </Modal>
                </Variant>
              );
            })}
        </Experiment>
      </GoogleReCaptchaProvider>
    ) : null;
  }
);

export default NewsletterPopups;
