import React, { FC } from 'react';
import IconCustom from 'components/common/IconCustom';

import { INewsLetterFormLabelComponentProps } from './model';
import './NewsLetterFormLabel.scss';

const NewsLetterFormLabel: FC<INewsLetterFormLabelComponentProps> = ({
  labelText,
  errorText,
  isValidInput,
}) => {
  return isValidInput === true || isValidInput === null ? (
    <span className="newsletter-form-info-label">{labelText}</span>
  ) : (
    <span className="newsletter-form-error-label">
      <IconCustom icon="error_icon red_error_icon" />
      {errorText}
    </span>
  );
};

export default NewsLetterFormLabel;
