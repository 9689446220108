import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';

import IconCustom from 'components/common/IconCustom';

import { INewsLetterPromocodeComponentProps } from './model';
import './NewsLetterPromocode.scss';

const NewsLetterPromocode: FC<INewsLetterPromocodeComponentProps> = ({ promocode }) => {
  const [copied, setCopied] = useState<boolean>(false);

  const onCopy = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = promocode?.code || '';
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    setCopied(true);
    document.body.removeChild(el);
  }, [promocode.code]);

  const classes = classNames('copy-command', { copied });

  return (
    <div className="newsletter-promocode">
      <span className="promocode">{promocode.code}</span>
      <button onClick={copied ? undefined : onCopy} className={classes} type="button">
        <IconCustom icon="clipboard_icon" />
        {copied ? promocode.copiedText : promocode.text}
      </button>
    </div>
  );
};

export default NewsLetterPromocode;
