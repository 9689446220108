import React, { FC, useCallback } from 'react';

import { INewsLetterFormInputProps } from './model';

const NewsLetterFormInput: FC<INewsLetterFormInputProps> = ({
  inputType,
  value,
  setIsValid,
  setValue,
  validationFunctions,
}) => {
  const onValidate = useCallback((e) => {
    const isValidated = validationFunctions.every((fn) => {
      return fn(e.target.value);
    });
    setIsValid(isValidated);
  }, []);

  const onChange = useCallback((e) => {
    setValue(e);
    setIsValid(null);
  }, []);

  return (
    <input
      type="text"
      value={value}
      name={inputType}
      aria-label={inputType}
      onChange={onChange}
      onBlur={onValidate}
    />
  );
};

export default NewsLetterFormInput;
