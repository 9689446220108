import React, { FC } from 'react';

import { NEWSLETTER_MODE } from 'utils/constants';
import GatsbyImage from 'components/common/GatsbyImage';
import useScreenRecognition from 'hooks/useScreenRecognition';
import NewsLetterPromocode from 'components/NewsLetter/NewsLetterPromocode';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { INewsLetterBannerComponentProps } from './model';
import './NewsLetterBanner.scss';

const NewsLetterBanner: FC<INewsLetterBannerComponentProps> = ({ viewMode, banner }) => {
  const { isMobile } = useScreenRecognition();
  const { thanksView } = banner;
  const { formView } = banner;
  const bannerBg =
    viewMode === NEWSLETTER_MODE.FORM ? formView?.bannerBgColor : thanksView?.bannerBgColor;

  const renderContent = (content) => {
    const elements: Array<React.ReactElement> = [];

    content?.subtitle &&
      elements.push(
        <p className="subtitle" key="subtitle">
          {content.subtitle}
        </p>
      );
    content?.title &&
      elements.push(
        <h5 className="title" key="title">
          {content.title}
        </h5>
      );
    content?.description &&
      elements.push(
        <DangerouslySetInnerHtml
          className="description"
          key="description"
          html={content.description}
        />
      );

    if (viewMode === NEWSLETTER_MODE.THANKS) {
      content?.promocode &&
        elements.push(
          <NewsLetterPromocode
            promocode={{
              code: content.promocode,
              text: content?.promocodeCopyText,
              copiedText: content?.promocodeCopiedText,
            }}
          />
        );
    }

    return elements;
  };

  const mobileMarkup = () => {
    return (
      <div
        className={`
        newsletter-responsive-banner
        newsletter-responsive-banner--${viewMode.toLowerCase()}
      `}
      >
        <div
          className="top-banner"
          style={
            bannerBg
              ? ({
                  backgroundColor: `var(--${bannerBg})`,
                } as React.CSSProperties)
              : undefined
          }
        >
          {viewMode === NEWSLETTER_MODE.FORM && renderContent(formView)}
          {viewMode === NEWSLETTER_MODE.THANKS && (
            <>
              <DangerouslySetInnerHtml
                className="text-logo"
                html={thanksView?.bannerLogo?.svg?.content}
              />
              <DangerouslySetInnerHtml
                className="sign-logo"
                html={thanksView?.bannerLogoSign?.svg?.content}
              />
            </>
          )}
        </div>

        {viewMode === NEWSLETTER_MODE.THANKS && (
          <div className="bottom-banner">{renderContent(thanksView)}</div>
        )}
      </div>
    );
  };

  const desktopMarkup = () => {
    return (
      <div
        className={`
        newsletter-banner
        newsletter-banner--${viewMode.toLowerCase()}
      `}
        style={
          bannerBg
            ? ({
                backgroundColor: `var(--${bannerBg})`,
              } as React.CSSProperties)
            : undefined
        }
      >
        <div className="newsletter-banner-centered">
          <div className="newsletter-banner-content-box">
            <DangerouslySetInnerHtml
              className="text-logo"
              html={formView?.bannerLogo?.svg?.content}
            />
            {viewMode === NEWSLETTER_MODE.FORM && renderContent(formView)}
            {viewMode === NEWSLETTER_MODE.THANKS && renderContent(thanksView)}
          </div>

          <div className="newsletter-banner-image-box">
            {formView?.bannerGlowingImage?.gatsbyImage?.childImageSharp && (
              <GatsbyImage
                className="glowing-image"
                fluid={formView?.bannerGlowingImage.gatsbyImage.childImageSharp?.fluid}
                alt={formView?.bannerGlowingImageAlt}
                style={{ position: 'absolute' }}
              />
            )}
            {viewMode === NEWSLETTER_MODE.FORM &&
              formView?.bannerCharacterImage?.gatsbyImage?.childImageSharp && (
                <GatsbyImage
                  className="character-image"
                  fluid={formView?.bannerCharacterImage.gatsbyImage.childImageSharp?.fluid}
                  alt={formView?.bannerCharacterImageAlt}
                  style={{ position: 'absolute' }}
                />
              )}
            {viewMode === NEWSLETTER_MODE.THANKS &&
              thanksView?.bannerCharacterImage?.gatsbyImage?.childImageSharp && (
                <GatsbyImage
                  className="character-image"
                  fluid={thanksView?.bannerCharacterImage.gatsbyImage.childImageSharp?.fluid}
                  alt={thanksView?.bannerCharacterImageAlt}
                  style={{ position: 'absolute' }}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  return isMobile ? mobileMarkup() : desktopMarkup();
};

export default NewsLetterBanner;
