import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState } from 'react';

import { pushSHAtoDataLayer } from 'utils/GTMhelpers';
import { IBody } from 'hooks/useNewsletterBody/useNewsletterBody';

interface IRequestStatus {
  state?: 'loading' | 'success' | 'error';
  message?: string;
}

const useNewsletterPost = (url) => {
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const postForm = async (reqBody: IBody) => {
    if (!executeRecaptcha) {
      return;
    }
    const gRecaptchaResponse = await executeRecaptcha('newsletterSignUp');
    setRequestStatus({
      state: 'loading',
    });
    const response = await fetch(`${url}?g-recaptcha-response=${gRecaptchaResponse}`, {
      method: 'POST',
      body: JSON.stringify(reqBody),
    });
    if (response.status >= 200 && response.status <= 299) {
      await response.json();
      pushSHAtoDataLayer(reqBody.Emails[0].EmailAddress, 'useNewsletterPost');
      setRequestStatus({
        state: 'success',
      });
    } else {
      setRequestStatus({
        state: 'error',
        message: `${response.status} ${response.statusText}`,
      });
    }
  };

  return {
    postForm,
    requestStatus,
  };
};

export default useNewsletterPost;
