import React, { FC, useState, useCallback, useEffect, ReactElement, Fragment } from 'react';
import dayjs from 'dayjs';

import { NEWSLETTER_FORM_ACTION } from 'utils/constants';

import useNewsletterPost from 'hooks/useNewsletterPost';
import useNewsletterBody from 'hooks/useNewsletterBody';

import NFSpinner from 'components/common/NFSpinner';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import CheckBoxCustom from 'components/common/CheckBoxCustom';
import NFButton from 'components/common/NFButton';
import NewsLetterFormInput from 'components/NewsLetter/NewsLetterFormInput';
import NewsLetterFormLabel from 'components/NewsLetter/NewsLetterFormLabel';
import IconCustom from 'components/common/IconCustom';
import { parseNFButton } from 'components/common/NFButton/parsers';

import {
  validateFieldExistence,
  validateFieldLimit,
  validateEmail,
  validateName,
} from 'components/NewsLetter/utils/helpers';

import { IFormState, INewsLetterFormComponentProps } from './model';
import './NewsLetterForm.scss';

const FORM_ID = 'newsletterForm';
const validationNameFns = [validateFieldExistence, validateFieldLimit, validateName];
const validationEmailFns = [validateFieldExistence, validateFieldLimit, validateEmail];

const NewsLetterForm: FC<INewsLetterFormComponentProps> = ({
  successCallback,
  body,
  onNewsletterSubmit,
}) => {
  const [formState, setFormState] = useState({
    Name: '',
    Email: '',
    PrivacyPolicy: undefined,
    SpecialOffer: true,
    ErrorMessage: '',
  } as IFormState);
  const [isValidNameField, setIsValidNameField] = useState<boolean | null>(null);
  const [isValidEmailField, setIsValidEmailField] = useState<boolean | null>(null);
  const { postForm, requestStatus } = useNewsletterPost('/.netlify/functions/newsletter-sign-up');
  const reqBody = useNewsletterBody(formState, body, FORM_ID);

  const onSubmit = async (e) => {
    e.preventDefault();
    const { Name, Email, PrivacyPolicy } = formState;
    const isValidName = validationNameFns.every((fn) => {
      return fn(Name);
    });
    const isValidEmail = validationEmailFns.every((fn) => {
      return fn(Email);
    });
    const inputsAreValid = isValidName && isValidEmail;
    setIsValidNameField(isValidName);
    setIsValidEmailField(isValidEmail);

    if (inputsAreValid && PrivacyPolicy && reqBody) {
      await postForm(reqBody);
    }

    if (!PrivacyPolicy) {
      setFormState((prev) => ({
        ...prev,
        PrivacyPolicy: false,
      }));
    }
  };

  const isCheckboxChecked = useCallback(() => {
    if (formState.PrivacyPolicy === undefined) return true;

    return formState.PrivacyPolicy;
  }, [formState.PrivacyPolicy]);

  const handleChange = (event) => {
    event.persist();
    setFormState((values) => ({
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }));
  };

  useEffect(() => {
    if (requestStatus?.state === 'success') {
      successCallback();
      onNewsletterSubmit({
        action: NEWSLETTER_FORM_ACTION.SUBMITTED,
        expiration: new Date(dayjs().add(1, 'years').format()),
      });
    }
    if (requestStatus?.state === 'error') {
      setFormState((prev) => ({
        ...prev,
        ErrorMessage: requestStatus?.message,
      }));
    }
  }, [requestStatus]);

  return (
    <form
      id={FORM_ID}
      className={`newsletter-form ${isCheckboxChecked() ? '' : 'newsletter-form-invalid'}`}
      onSubmit={onSubmit}
    >
      {requestStatus?.state === 'loading' ? <NFSpinner isSpinning /> : null}
      <fieldset>
        <legend>
          <span className="accessibility-hidden">{body.campaignName}</span>
        </legend>

        <div className="newsletter-form-narrowed-block">
          {body.inputs.map(({ properties: { label, validationMessage, inputType } }) => (
            <div className="newsletter-form-fieldset" key={`${inputType}-${validationMessage}`}>
              <NewsLetterFormLabel
                labelText={`${label}*`}
                errorText={validationMessage}
                isValidInput={inputType === 'Name' ? isValidNameField : isValidEmailField}
              />
              <NewsLetterFormInput
                inputType={inputType}
                value={inputType === 'Name' ? formState.Name : formState.Email}
                setValue={handleChange}
                setIsValid={inputType === 'Name' ? setIsValidNameField : setIsValidEmailField}
                validationFunctions={inputType === 'Name' ? validationNameFns : validationEmailFns}
              />
            </div>
          ))}

          <div className="newsletter-form-fieldset">
            <small>{body.requiredLabel}</small>
          </div>
        </div>

        <div className="newsletter-form-full-width-block">
          <DangerouslySetInnerHtml className="newsletter-form-text-offer" html={body.consentText} />

          {body.checkboxes.map((checkbox) => {
            const { checkboxType, businessId } = checkbox;
            let checkboxHtml: ReactElement | null;
            switch (checkboxType) {
              case 'PrivacyPolicy':
                checkboxHtml = (
                  <Fragment key={`${checkboxType}-${businessId}`}>
                    <CheckBoxCustom
                      id={checkboxType}
                      name={checkboxType}
                      isDisabled={false}
                      onChange={handleChange}
                      labelText={checkbox.label}
                      bgColor="red"
                      signColor="white"
                    />
                    <div className="newsletter-form-warning-text">
                      <IconCustom icon="error_icon red_error_icon" />
                      <small>{body.checkboxValidationMsg}</small>
                    </div>
                  </Fragment>
                );
                break;
              case 'SpecialOffer':
                checkboxHtml = (
                  <DangerouslySetInnerHtml
                    className="newsletter-form-text-offer"
                    html={checkbox.label}
                    key={`${checkboxType}-${businessId}`}
                  />
                );
                break;
              default:
                checkboxHtml = null;
            }

            return checkboxHtml;
          })}

          {formState.ErrorMessage && (
            <div className="newsletter-form-error-message">{formState.ErrorMessage}</div>
          )}

          <div className="newsletter-form-centered-content">
            <NFButton
              type="submit"
              {...parseNFButton(body.signUpBtn[0].properties)}
              variant="primary"
              size="md"
            />
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default NewsLetterForm;
