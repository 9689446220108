import { nanoid } from 'nanoid';
import dayjs from 'dayjs';

import { IFormState } from 'components/NewsLetter/NewsLetterForm/model';
import { INewsletterBodyFormView } from 'components/NewsLetter/model';
import { useEffect, useState } from 'react';

export type IBody = {
  TierCode: string;
  Emails: [
    {
      EmailAddress: string;
      DeliveryStatus: string;
    }
  ];
  JsonExternalData: {
    UnmappedAttributes: {
      [key: string]: any;
    };
    Agreements: {
      BusinessId: string;
      RevisionId: string;
      ConsentDesc: string;
      MandatoryInd: boolean;
      ConsentAcceptedInd: boolean;
    }[];
  };
};

const useNewsletterBody = (
  formState: IFormState,
  body: INewsletterBodyFormView,
  formId: string
) => {
  const [reqBody, setReqBody] = useState<IBody>();

  useEffect(() => {
    setReqBody({
      TierCode: 'RBGBRNURTIER1',
      Emails: [
        {
          EmailAddress: formState.Email,
          DeliveryStatus: 'G',
        },
      ],
      JsonExternalData: {
        UnmappedAttributes: {
          email: formState.Email,
          name: formState.Name,
          optinemail: true,
          lead_customer_brand_opt_in: true,
          rb_commercial_brand_opt_in: true,
          rb_symptoms: '',
          brand: process.env.GATSBY_LDS_BRAND,
          channel_source: `${process.env.GATSBY_LDS_BRAND} WebSite`,
          campaign_type_name: body.campaignName,
          fromUrl: process.env.SITE_URL,
          token: nanoid(),
          token_time: dayjs().unix(),
          formId,
        },
        Agreements: [
          ...body.checkboxes.map(
            ({ businessId, revisionId, checkboxType, consentDescription }) => ({
              BusinessId: businessId,
              RevisionId: revisionId,
              ConsentDesc: consentDescription,
              MandatoryInd: true,
              ConsentAcceptedInd: formState[checkboxType] || false,
            })
          ),
        ],
      },
    });
  }, [formState]);

  return reqBody;
};

export default useNewsletterBody;
