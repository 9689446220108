const MAX_FIELD_LENGTH = 50;

export const validateFieldExistence = (value: string) => {
  return value.trim() !== '';
};

export const validateFieldLimit = (value: string) => {
  return value.length <= MAX_FIELD_LENGTH;
};

export const validateEmail = (email: string) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(email.toLowerCase());
};

export const validateName = (name: string) => {
  return /([a-zA-Z `'"’,.:;!?-])/.test(name);
};
