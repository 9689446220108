import sha256 from 'sha256';
import { isBrowser } from './browser';

declare global {
  interface Window {
    dataLayer: any[];
    Krux: (
      namespace: string,
      consent: string,
      errorHandler: (err: any) => void,
      data: { [key: string]: boolean }
    ) => void;
  }
}

export const pushSHAtoDataLayer = (email: string, usage: string) => {
  if (!email) {
    return;
  }
  const data = email.trim().toLowerCase();
  if (!data) {
    return;
  }
  if (isBrowser()) {
    window.dataLayer && window.dataLayer.push({ email_sha256: sha256(data) });
    window.Krux &&
      window.Krux(
        'ns:reckittbenckiserukhealth',
        'page:load',
        (err) => {
          console.error(`Krux at ${usage} has an error: ${err}`);
        },
        { pageView: true }
      );
  }
};

export default { pushSHAtoDataLayer };
