import React, { FC, ReactElement } from 'react';

import './NFSpinner.scss';
import { IPropsNFSpinner } from './model';

const NFSpinner: FC<IPropsNFSpinner> = (props): ReactElement => {
  const { children, isSpinning } = props;

  return (
    <div className={`nf-spinner ${children ? '' : 'full-screen'}`}>
      {isSpinning ? (
        <>
          <div className="nf-spinner__overlay" />
          <span className="sr-only">Loading...</span>
          <div className="nf-spinner__animated" />
        </>
      ) : null}

      {children}
    </div>
  );
};

export default NFSpinner;
