import React, { FC, useState, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';

import { NEWSLETTER_MODE } from 'utils/constants';
import NewsLetterBanner from 'components/NewsLetter/NewsLetterBanner';
import NewsLetterForm from 'components/NewsLetter/NewsLetterForm';
import NewsLetterThanksView from 'components/NewsLetter/NewsLetterThanksView';

import { INewsLetterComponentProps } from './model';
import 'components/NewsLetter/NewsLetter.scss';

const NewsLetter: FC<INewsLetterComponentProps> = ({ banner, body, onNewsletterSubmit }) => {
  const [documentElement, setDocumentElement] = useState<HTMLElement>();
  useEffect(() => {
    setDocumentElement(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [documentElement]);

  const [viewMode, setViewMode] = useState<NewsletterTypes.TNewsLetterViewMode>(
    NEWSLETTER_MODE.FORM
  );

  const successFormCallback = useCallback(() => {
    setViewMode(NEWSLETTER_MODE.THANKS);
  }, []);

  return (
    <div className="newsletter-wrapper">
      <NewsLetterBanner viewMode={viewMode} banner={banner} />

      <div className="newsletter-content-area">
        {viewMode === NEWSLETTER_MODE.FORM && (
          <NewsLetterForm
            body={body.formView}
            successCallback={successFormCallback}
            onNewsletterSubmit={onNewsletterSubmit}
          />
        )}

        {viewMode === NEWSLETTER_MODE.THANKS && <NewsLetterThanksView body={body.thanksView} />}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentNewsletterPopup on INewsletterPopup {
    id
    alias
    newsletterBanner {
      properties {
        title
        subtitle
        bannerBgColor {
          ...FragmentColorProps
        }
        bannerCharacterImage {
          fallbackUrl
          url
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 270) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        bannerCharacterImageAlt
        bannerGlowingImage {
          fallbackUrl
          url
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 470) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        bannerGlowingImageAlt
        bannerLogo {
          fallbackUrl
          svg {
            content
          }
        }
      }
    }
    formInputs {
      properties {
        isRequired
        label
        validationMessage
        inputType
      }
    }
    requiredLabel
    consentText
    checkboxes {
      businessId
      consentDescription
      label
      revisionId
      checkboxType
    }
    checkboxValidationMsg
    campaignName
    closeIconColor {
      ...FragmentColorProps
    }
    closeBtnAriaLabel
    signUpBtn {
      properties {
        ...FragmentNFButton
      }
    }
    thankYouPopup {
      properties {
        thankYouBanner {
          properties {
            title
            subtitle
            description
            promocode
            promocodeCopyText
            promocodeCopiedText
            bannerBgColor {
              ...FragmentColorProps
            }
            bannerCharacterImage {
              fallbackUrl
              url
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 270) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            bannerCharacterImageAlt
            bannerGlowingImage {
              fallbackUrl
              url
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 470) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            bannerGlowingImageAlt
            bannerLogo {
              fallbackUrl
              svg {
                content
              }
            }
            bannerLogoSign {
              fallbackUrl
              svg {
                content
              }
            }
          }
        }
        yourPharmacyLogo {
          properties {
            ariaLabel
            link
            logo {
              fallbackUrl
              url
              svg {
                content
              }
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        productsRangeBtn {
          properties {
            ...FragmentNFButton
          }
        }
        partnersList {
          properties {
            ariaLabel
            link
            logo {
              fallbackUrl
              url
              svg {
                content
              }
              gatsbyImage {
                childImageSharp {
                  fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default NewsLetter;
